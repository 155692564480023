import {onFind} from "@elements/init-modules-in-scope";
import {on, addClass, hasClass, removeClass, findIn, findAllIn, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-period-dates', function (element) {
        let periodDatesBtn = findIn('.js-period-dates__btn', element);

        on('click', function(e) {
            let periodDates = closest('.js-period-dates', e.target);
            let periodDatesDatepicker = findIn('.js-period-dates__datepicker', periodDates);
            let datepickerInputs = findAllIn('input',periodDatesDatepicker);
            let periodDatesCollapse = findIn('.js-period-dates__collapse',periodDates);

            if (hasClass('is-open', e.target)){
                datepickerInputs.forEach((item) => {
                    item.setAttribute('disabled', 'disabled');
                });
                removeClass('is-open', e.target);
                removeClass('show', periodDatesCollapse);
            }else{
                datepickerInputs.forEach((item) => {
                    item.removeAttribute('disabled');
                });
                addClass('is-open', e.target);
                addClass('show', periodDatesCollapse);
            }

        }, periodDatesBtn);
    });
}

