import {on,find} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {createAjaxForm} from "@elements/ajax-form";

const selectors = {
    base: '.js-demi-ajax-form',
    result: '.js-demi-ajax-form__result',
    loading: '.js-demi-ajax-form__loading',
    notifications: '.js-demi-ajax-form__notifications',
    form: '.js-demi-ajax-form__form',
    additionalForm: '.js-demi-ajax-form__additional-form',
    errorArea: '.js-demi-ajax-form__error-area',
    retry: '.js-demi-ajax-form__retry',
    link: '.js-demi-ajax-form__link',
    reset: '.js-demi-ajax-form__reset',
};

const options = {
    addUrlParams: true,
    fetchHeaders: {'format': 'json'}
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let api = createAjaxForm(
            baseElement,
            options,
            selectors
        );

        on('reset.ajax-form', (evt) => {
            api.submit();
        }, baseElement);

        on('success.ajax-form', (evt) => {
            let target = evt.target.getAttribute('data-ajax-form-scroll-target');

            if(find(target)){
                let bodyRect = document.body.getBoundingClientRect().top;
                let elementRect = find(target).getBoundingClientRect().top;
                let elementPosition = elementRect - bodyRect;
                let offsetPosition = elementPosition - 150;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, baseElement);
    });
}
