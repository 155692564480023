import ScrollSpy from 'bootstrap.native/dist/components/scrollspy-native.esm';
import {find} from "@elements/dom-utils";
import {init as baseInit} from "./anchor-nav"

export function initScrollSpy(options) {
    let scrollspyTarget = null;

    if (options.scrollSpyTarget){
        scrollspyTarget = find(options.scrollSpyTarget);
    }

    new ScrollSpy(document.body, {
        target: scrollspyTarget,
        offset: options.scrollSpyOffset,
        method: options.scrollSpyMethod
    })
}

export const init = params => baseInit({
    initScrollSpy,
    ...params
});
