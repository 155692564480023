"use strict";

import {findIn, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-tile-gallery', function (sliderElement) {

        let slidesPerViewDesktop = 3,
            slidesPerViewMobile = 3;

        let swiperEl = createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                loop: false,
                slidesPerView: slidesPerViewMobile,
                spaceBetween: 10,
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        spaceBetween: 30,
                        slidesPerView: slidesPerViewDesktop
                    }
                },
                scrollbar: {
                    el: '.js-tile-gallery__scrollbar',
                    hide: false,
                },
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-tile-gallery__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-tile-gallery__prev', swiperContainer),
                },
            },
            {
                sliderContainer: '.js-tile-gallery',
                slider: '.js-tile-gallery__slider',
                sliderPrev: '.js-tile-gallery__prev',
                sliderNex: '.js-tile-gallery__next',
                sliderIndex: '.js-tile-gallery__current-index'
            });
    });
    
}