import './scripts/common';
import './scripts/common-nav';
import {find} from "@elements/dom-utils";

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import {onFind} from "@elements/init-modules-in-scope";
import {renderAllTemplates} from "./scripts/renderTemplate";


import * as lightbox from './scripts/elements-packages/lightbox';
lightbox.init();

import * as imgTextSlider from './scripts/img-text-slider';
imgTextSlider.init();

import * as gallery from './scripts/gallery';
gallery.init();

import * as tileGallery from './scripts/tile-gallery';
tileGallery.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as affix from '@elements/affix';
affix.init();

import * as hotspot from './scripts/hotspot';
hotspot.init();

import * as experienceSlider from './scripts/experience-slider';
experienceSlider.init();

import * as demiAjaxForm from './scripts/demi-ajax-form';
demiAjaxForm.init();

import {init} from './scripts/anchor-nav/bootstrap-4-native';
setTimeout(function() {
    init(
        {
            renderItem: function (text, anchor) {
                return '<li class="nav-item anchor-nav__item list-inline-item js-anchor-nav__link-wrapper"><a class="anchor-nav__link js-anchor-nav__link js-smoothscroll__link" data-scroll-offset="200" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
            },
            smoothScroll: true,
            scrollSpy: true,
            scrollSpyTarget: '.js-anchor-nav',
            scrollSpyOffset: 220,
        },
        {
            list: '.js-anchor-nav__list',
            item: '.js-anchor-nav__item'
        }
    );

}, 600);


import * as anchorNavExtension from './scripts/anchor-nav-extension';
import * as floatingLabel from "@elements/floating-labels";
import {isValid, isValidInput} from "./scripts/formValidation";
anchorNavExtension.init();

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as centerActiveTab from './scripts/center-active-tab';
centerActiveTab.init();

import * as periodDates from './scripts/period-dates';
periodDates.init();

import * as availabilityDatepicker from '@elements/datepicker';

if (find('.js-availability-datepicker')){
    availabilityDatepicker.createDatepicker(find('.js-availability-datepicker'),
        {
            showMonths: 2
        },
        {
            base: '.js-availability-datepicker',
            input: '.js-availability-datepicker__input',
            altField: '.js-availability-datepicker__alt-field'
        });

}

import * as toscInit from './scripts/tosc-init';
toscInit.init();


import * as showMore from './scripts/show-more';
showMore.init();

import * as customVideoControls from './scripts/custom-video-controls';
customVideoControls.init();


import * as smoothScroll from "@elements/smooth-scroll";
let options = {
    scrollOffset: 170
}

smoothScroll.init(options);

import * as hashCash from './scripts/hashCash';
// hashCash.init();