"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {addClass, find, closest, on, findIn,findAll, findAllIn, removeClass} from "@elements/dom-utils";
import {renderAllTemplates} from "./renderTemplate";

const selectors = {
    base: '.js-anchor-nav',
    link: '.js-anchor-nav__link',
    list: '.js-anchor-nav__list',
    item: '.js-anchor-nav__item',
    linkWrapper: '.js-anchor-nav__link-wrapper',
    prev: '.js-anchor-nav__prev',
    next: '.js-anchor-nav__next'
};

let alreadyRendered = false;

export function init() {

    onFind(selectors.base, function (anchorNav) {

        if(!alreadyRendered) {
            renderAllTemplates();
            alreadyRendered = true;
        }


        on('anchor-nav/initialized', (evt) => {
            let items = findAll(selectors.item);

            let idOfFirstItem = items[0].getAttribute('id');

            let anchorNavNext = find(selectors.next);
            let anchorNavPrev = find(selectors.prev);

            anchorNavNext.setAttribute('href', '#' + idOfFirstItem);
            addClass('disabled',anchorNavPrev);

        },anchorNav);


        document.body.addEventListener('activate.bs.scrollspy', function (evt) {
            let activeLink = evt.relatedTarget;

            handleActiveLink(activeLink);
        });

    });
}

function handleActiveLink(activeLink){
    let activeLinkWrapper = closest(selectors.linkWrapper, activeLink);
    let linkList = closest(selectors.list, activeLink);

    let activeLinkIndex = [...linkList.children].indexOf(activeLinkWrapper);

    let prevLink = find(selectors.prev);
    let nextLink = find(selectors.next);


    if (linkList.children[activeLinkIndex - 1]) {
        let prevHref = findIn(selectors.link, linkList.children[activeLinkIndex - 1]).getAttribute('href');
        prevLink.setAttribute('href', prevHref);
        removeClass('disabled', prevLink);
    } else {
        addClass('disabled', prevLink);
    }

    if (linkList.children[activeLinkIndex + 1]) {
        let nextHref = findIn(selectors.link, linkList.children[activeLinkIndex + 1]).getAttribute('href');
        nextLink.setAttribute('href', nextHref);
        removeClass('disabled', nextLink);
    } else {
        addClass('disabled', nextLink);
    }

    center(linkList, activeLinkWrapper);
}

function center(scrollParent, activeLi) {
    if(activeLi) {
        let childPos = activeLi.offsetLeft, // position of Li in scrollparent
            offset = childPos -  scrollParent.offsetWidth / 2 + activeLi.getBoundingClientRect().width / 2 - scrollParent.offsetLeft;

        scrollParent.scroll({
            left: offset,
            behavior: "smooth"
        });
    }
}
