"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, on, hasClass, removeClass, setAttribute} from '@elements/dom-utils';

const selectors = {
    video: '.js-custom-video-controls__video',
    button: '.js-custom-video-controls__button',
    base: '.js-custom-video-controls'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let button = findIn(selectors.button, baseElement);
        let video = findIn(selectors.video, baseElement);
        let playTk = button.getAttribute('data-custom-video-controls-play-tk');
        let pauseTk = button.getAttribute('data-custom-video-controls-pause-tk');

        on('click', function () {
            if(hasClass('is-playing', button)){
                video.pause();
                removeClass('is-playing', button);
                setAttribute('aria-label', playTk, button);
            }else{
                video.play();
                addClass('is-playing', button);
                setAttribute('aria-label', pauseTk, button);
            }
        }, button);
    });
}
