"use strict";

import {findIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-img-text-slider', function (sliderElement) {
        let slidesPerViewDesktop = 1,
            slidesPerViewMobile = 1;

        createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                loop: false,
                speed: 500,
                slidesPerView: slidesPerViewMobile,
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: slidesPerViewDesktop
                    }
                },
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-img-text-slider__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-img-text-slider__prev', swiperContainer),
                },
            },
            {
                sliderContainer: '.js-img-text-slider',
                slider: '.js-img-text-slider__slider',
                sliderPrev: '.js-img-text-slider__prev',
                sliderNex: '.js-img-text-slider__next'
            })
    });
}