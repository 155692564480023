"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, getAttribute, addClass, on, closest, findAllIn} from "@elements/dom-utils";

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {
    onFind('.js-hotspot-container', function ($container) {
        let trigger = $container.getAttribute('data-hotspot-on-hover') && !isMobile() ? 'mouseover' : 'click';
        let toggleButtons = findAllIn('.js-hotspot__toggle', $container);

        toggleButtons.forEach(function($this) {
            if(trigger === 'click' || hasClass('js-hotspot__toggle-close', $this)) {
                on('click', function () {
                    let $parent = closest('.js-hotspot', $this);
                    if (hasClass('is-open', $parent)) {
                        removeClass('is-open', $parent);
                    } else {
                        addClass('is-open', $parent);

                        bodyClickHandler($parent);
                    }
                }, $this);
            } else if(trigger === 'mouseover') {
                on('mouseover', function () {
                    toggleButtons.map(function(item) {
                        removeClass('is-open', closest('.js-hotspot', item))
                    });
                    let $parent = closest('.js-hotspot', $this);
                    addClass('is-open', $parent);

                    bodyClickHandler($parent);
                }, $this);
            }

        });
    });
}

function bodyClickHandler($parent) {
    window.addEventListener("click", function _listener(evt) {
        if (!$parent.contains(evt.target)) {
            removeClass('is-open', $parent);

            window.removeEventListener("click", _listener, true);
        }
    }, true);
}