import {closest, addClass, removeClass, on, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";


export function init () {
    onFind('.js-show-more', function (element) {
        let showMoreContainer = closest('.js-show-more-container', element),
            showMoreBtn = findIn('.js-show-more__btn', showMoreContainer),
            maxHeight = element.getAttribute('data-show-more-max-height') || 120;

        if(element.offsetHeight > maxHeight){
            addClass('show-more', showMoreContainer);
            element.style.maxHeight = maxHeight + 'px';
        }

        on('click', function(e) {
            removeClass('show-more', showMoreContainer);
        }, showMoreBtn);
    });
}
